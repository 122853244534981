/*- Screen Sizes*/
/*import fonts*/
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");
@import url("https://fonts.googleapis.com/css?family=Oswald");
/*media queries*/
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.wrapper {
  margin: 3.125em auto;
  padding: 1em 5em 1em;
  max-width: 1300px;
}

h1 {
  color: white;
  font-family: "Open Sans", "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
  text-align: center;
}

/* - &:nth-child(1) {
			 grid-area: entry1;
		 }
   - useful timesaver if there is a lengthy list
*/
.timeline {
  line-height: 1.5em;
  font-size: 14px;
  transition: all 0.4s ease;
  position: relative;
  counter-reset: section;
}

.timeline:before {
  content: "";
  width: 10px;
  height: 100%;
  background: #ffffff;
  position: absolute;
  top: 0;
  left: -3.313em;
  border-radius: 40px 40px;
}

.timeline--entry {
  position: relative;
  background-color: #eeeeee00;
}
.timeline--entry__title {
  color: white;
  background-color: #1350c23d;
  font-weight: 300;
  font-size: 1rem;
  padding: 1em;
  font-size: 25px;
  letter-spacing: 2px;
  text-shadow: 0 0 10px #FFFFFF;
}
.timeline--entry__title:before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -1em;
  border-right: 10px solid #1350c2;
}

.timeline--entry__detail {
  background-color: #000000;
  padding: 1em;
  margin: 0;
  font-size: 20px;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
}

.timeline--entry:before {
  content: "";
  font-family: "Oswald", Georgia, Cambria, "Times New Roman", Times, serif;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3em;
  height: 3em;
  background-color: #000000;
  border: 0.2em solid rgb(255, 255, 255);
  border-radius: 50%;
  position: absolute;
  counter-increment: section;
  content: counter(section);
  text-shadow: 0 1px 0 #424242;
  left: -4.5em;
}

@supports (display: grid) {
  @media (min-width: 768px) {
    .timeline {
      display: grid;
      grid-gap: 4.75em;
      grid-template-areas: ".  entry1" "entry2  ." ". entry3" "entry4 ." ". entry5";
    }
    .timeline:before {
      left: 51.3%;
    }

    .timeline--entry:nth-child(1) {
      grid-area: entry1;
    }
    .timeline--entry:nth-child(2) {
      grid-area: entry2;
    }
    .timeline--entry:nth-child(3) {
      grid-area: entry3;
    }
    .timeline--entry:nth-child(4) {
      grid-area: entry4;
    }
    .timeline--entry:nth-child(5) {
      grid-area: entry5;
    }
    .timeline--entry:nth-of-type(odd):before {
      left: -3.8em;
    }
    .timeline--entry:nth-of-type(even):not(:nth-of-type(odd)) .timeline--entry__title:before {
      left: 100%;
      border-left: 10px solid #1350c2;
      border-right: 0;
    }
    .timeline--entry:nth-of-type(even):before {
      left: 103%;
    }
  }
}