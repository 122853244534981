* {
  font-family: 'Orbitron', sans-serif;
}

@import url('https://fonts.cdnfonts.com/css/galactic-vanguardian-ncv');
@import url('https://fonts.cdnfonts.com/css/zilap-orion-personal-use');
@import url('http://fonts.cdnfonts.com/css/geomanist');

a {
  color: rgb(193, 208, 248);
  font-size: large;
  cursor: pointer;
}

@media screen and (max-width: 499px) {

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 25px;
    font-weight: bolder;
    letter-spacing: 5px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
    text-align: center;
    width: 100%;
  }

  .rmH {
    font-size: 25px;
    font-weight: bolder;
    letter-spacing: 5px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }


  .rarityCon1{
    font-size: 18px;
  }

  .rarityCon2{
    font-size: 18px;
  }
  
  .faqH {
    font-size: 25px;
    font-weight: bolder;
    letter-spacing: 5px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 25px;
    font-weight: bolder;
    letter-spacing: 5px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 12px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 100%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 350px;
    height: 460px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 300px;
    height: 400px;
    margin-bottom: 8%;
  }
  
  .uR {
    width: 100%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 27px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 27px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 60%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;

  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    width: 37%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord {
    padding-right: 20px;
    transition: transform .2s;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 90%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
  
  }
  
  
  .title {
    font-size: 25px;
    letter-spacing: 10px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -70%;
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: fit-content;
    flex-flow: column nowrap;
  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 5%;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 13px;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
 /*.............. FAQ ..............*/


summary {
  font-size: 16px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 90%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 16px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg6.jpg')no-repeat center center fixed;
}


  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 20px;
    text-align: center;
    padding-top: 2%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 15px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    width: 90%;
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 90%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
    margin-bottom: 15%;

  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
  
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  
  .faqH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 18px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 18px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 100%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 350px;
    height: 460px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 95%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 390px;
    height: 520px;
    margin-bottom: 8%;
  }
  
  .uR {
    width: 100%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 40px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 30px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;

  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 3%;
    margin-left: auto;
    margin-right: auto;
    width: 27%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord {
    padding-right: 20px;
    transition: transform .2s;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 70%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
  
  }
  
  
  .title {
    font-size: 25px;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
  
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    width: fit-content;
    flex-flow: column nowrap;

  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 2%;
    position: static;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 13px;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
 /*.............. FAQ ..............*/


summary {
  font-size: 18px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 90%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 18px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg6.jpg')no-repeat center center fixed;
}


  
  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 20px;
    text-align: center;
    padding-top: 2%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    width: 70%;
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 50%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
    margin-bottom: 15%;

  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {


  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
  
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  .faqH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 35px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 20px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 20px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 100%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 500px;
    height: 657px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10%;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 18px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 390px;
    height: 520px;
    margin-bottom: 8%;
  }
  
  .uR {
    width: 100%;
    flex-flow: column nowrap;
    display: flex;
    justify-content: center;
    margin-top: 3%;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 40px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 30px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;

  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 18%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord {
    padding-right: 20px;
    transition: transform .2s;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 53%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
  
  }
  
  
  .title {
    font-size: 33px;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    width: 55%;
  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 2%;
    position: static;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
  /*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg6.jpg')no-repeat center center fixed;
}


  
  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 23px;
    text-align: center;
    padding-top: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 20px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    width: 70%;
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 50%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
  
  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
  
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  .faqH {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 20px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 20px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 100%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 400px;
    height: 526px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 19px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 280px;
    height: 373px;
  }
  
  .uR {
    width: 100%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
  }
  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 35px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 30px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;
  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord {
    padding-right: 20px;
    transition: transform .2s;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 40%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
  
  }
  
  
  .title {
    font-size: 40px;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    width: 40%;
  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 2%;
    position: static;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
    letter-spacing: 2px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
  
  /*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg6.jpg')no-repeat center center fixed;
}


  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 23px;
    text-align: center;
    padding-top: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 25px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 30%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
  
  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
/*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg6.jpg')no-repeat center center fixed;
}


.osPic {
  width: 30px;
  height: 30px;
  cursor: pointer;

}

.price {
  text-align: center;
  padding: 15px;
  font-size: 20px;
  color: #ffffff;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
  contain: content;
}


.boxWrap2 {
  display: flex;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
  background-size: cover;

}

.boxWrap5 {
  display: flex;
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;

  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap5 {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
}

.boxWrap3 {
  width: 100%;
  padding-top: 5%;
  padding-bottom: 5%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
  background-size: cover;
}

.boxWrap4 {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  font-size: 50px;
  font-weight: bolder;
  letter-spacing: 20px;
  background: #13B2FD;
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  -webkit-background-clip: text;
  font-family: 'Zilap Orion Personal Use', sans-serif;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2%;
  text-align: center;
}

.rmH {
  font-size: 50px;
  font-weight: bolder;
  letter-spacing: 20px;
  background: #13B2FD;
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  -webkit-background-clip: text;
  font-family: 'Zilap Orion Personal Use', sans-serif;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2%;
}

.faqH {
  font-size: 50px;
  font-weight: bolder;
  letter-spacing: 20px;
  background: #13B2FD;
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  -webkit-background-clip: text;
  font-family: 'Zilap Orion Personal Use', sans-serif;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

.utilH {
  font-size: 50px;
  font-weight: bolder;
  letter-spacing: 20px;
  background: #13B2FD;
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  -webkit-background-clip: text;
  font-family: 'Zilap Orion Personal Use', sans-serif;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 5px;
}

.rarityAndNum {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 20px;
  margin-top: 10px;
}

.rarityAndNum div {
  font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
}

.rarityAndNum1 div {
  font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  
}

.rarityAndNum1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 20px;
}

.rarityBg {
  background-color: rgb(134, 147, 151);
  padding: 20px;
  width: 85%;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  margin-top: 10%;
  color: rgba(255, 255, 255, 0.753);

  /* From https://css.glass */
background: rgba(255, 255, 255, 0.164);
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
}

.picSet {
  width: 600px;
  height: 789px;
}

.roadmapH {
  font-size: 100px;
  text-shadow: 4px 3px 0 #3a5a3f;
  color: white;
  margin-bottom: 10px;
  font-family: 'KARASHA', sans-serif;
  text-align: center;
}

.storyCon {
  color: white;
  font-size: 20px;
  letter-spacing: 3px;
  font-weight: 100;
}

.storyPic {
  width: 400px;
  height: 600px;

}

.storyRight {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  text-align: center;
}

.ggPic {
  width: 390px;
  height: 520px;
}

.uR {
  width: 100%;
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}

.rarityBg2 {
  flex-flow: column nowrap;
}

.rarityCon {
  flex-flow: column nowrap;
  color: white;
  font-size: 20px;
  letter-spacing: 3px;
}


/* CSS */
.button-87 {
  margin-top: 5%;
  padding: 15px 60px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: 0px;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  letter-spacing: 3px;
}

.button-87:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.button-87:active {
  transform: scale(0.95);
}

.rarityBtn::after {
  left: -100%;
  clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
  background-color: rgba(255, 255, 255, 0.6);
  transition: all 300ms ease;

}


.rarityBtn:hover:after {
  left: 100%;

}

.oPSubTitle {
  color: white;
  font-size: 50px;
  background-color: #0A0068;
  text-align: center;
}

.oPSubTitle2 {
  color: white;
  font-size: 50px;
  background-color: #0a006800;
  text-align: center;
}

.container {
  position: relative;
  width: fit-content;

}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background: #13B2FD;
  background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  letter-spacing: 2px;

}


.utilRow {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  margin-top: 1%;
}

.utilRow2 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.bgUtilMain {
  background-color: #e0706c;
  width: 60%;
}

.ninja {
  width: 220px;
  height: 522px;
}

.ninja2 {
  width: 250px;
  height: 400px;
}

.ninjaDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.bgUtil {
  width: 70%;
  background-color: #273a2a;
  padding: 20px;
  border-radius: 20px;
  border-style: groove;
  border-color: white;
  border-width: 2px;
}

.bgUtil2 {
  width: 70%;
  background-color: #273a2a;
  padding: 20px;
  margin-top: 3%;
  border-radius: 20px;
  border-style: groove;
  border-color: white;
  border-width: 2px;
}

.intro {
  color: white;
  font-size: 150px;
  text-align: center;
  text-shadow: 4px 3px 0 #273a2a;
  font-family: 'KARASHA', sans-serif;

}

.intro2 {
  color: rgba(255, 255, 255, 0.705);
  font-size: 20px;
  text-align: center;
  text-shadow: 4px 3px 0 #383d6e18;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255, 255, 255, 0.055);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 15px;
  color: #e0706c;
  text-align: center;

}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 20px;
  text-align: center;
  color: rgb(204, 204, 204);
}

.loadTextSub {
  text-align: center;
  color: rgb(185, 185, 185);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  justify-content: space-evenly;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
  justify-content: space-between;
}

.logoB {
  width: 53px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.logoBDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  padding-bottom: 13px;

}

.discord {
  padding-right: 20px;
  transition: transform .2s;
}

.discord:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
}

.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  padding-top: 5px;
  margin-left: 10%;

}

.connect2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
  padding-top: 5px;
  margin-right: 2%;
}

.walletAndSocial {
  display: flex;
  justify-content: space-between;
  width: 36%;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
  color: white;
  padding-top: 15px;
  font-size: 16px;


}

.connect div:hover {
  margin-left: 10px;
  margin-right: 10px;
  font-family: 'Poppins', sans-serif;
  color: rgb(184, 184, 184);
  padding-top: 15px;
  cursor: pointer;

}


@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

}

.mintbuttondiv {
  margin-top: 20px;
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  margin-top: 20px;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
  cursor: pointer;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 10px;
  width: 50px;
  border: none;
  border-radius: 10px;
  box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
  cursor: pointer;

}


.btnfos-0-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
}


.btnfos-0-2-2:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
}


.btnfos-0-3 {
  margin-top: 20px;
  font-size: 25px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  padding: 10px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  width: 200px;
  box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
}


.btnfos-0-3:hover {

  color: rgb(78, 78, 78);
  cursor: pointer;

}

.btnfos-0-3:hover:active {
  background-color: #d3d3d3;
  box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
}

.nftamount {

  color: #08bb52;
  font-size: 60px;
  font-family: 'Titan One', cursive;
  text-shadow: 4px 3px 0 #383d6e17;
}

.walletConnect {
  display: flex;
  justify-content: center;
}

.walletConnectOs {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: rgb(124, 199, 74);
}


.headers {
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  justify-content: center;
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
}

.navBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 40%;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  text-align: center;
  font-family: 'Rubik', sans-serif;
}

.rows:hover {
  color: #ffffffb2;

}


.title {
  font-size: 40px;
  letter-spacing: 20px;
  background: #13B2FD;
  background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  -webkit-background-clip: text;
  font-family: 'Zilap Orion Personal Use', sans-serif;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 2%;
}

.socialContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 8%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2%;
}

.row1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  color: white;
}

.introduction {

  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}


.wallet2 {
  background-color: #ffffff;
  border-style: solid;
  color: rgb(0, 0, 0);
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: transform .2s;
}

.wallet2:hover {
  color: #62a06b;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;
  font-family: 'Poppins', sans-serif;
  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(73, 87, 165);
  box-shadow: rgb(35, 42, 78) 0px 5px;
  border: none;
  color: white;
  font-size: 35px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px;
  width: 250px;
}


.walletOS {
  margin-left: auto;
  margin-right: auto;
  background-color: rgb(73, 87, 165);
  box-shadow: rgb(35, 42, 78) 0px 5px;
  border: none;
  color: white;
  font-size: 35px;
  border: none;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px;
  width: 250px;

}

.walletOS:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.mintAndOS {
  display: flex;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-bottom: 50px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;

}

.mintAndOS2 {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.btn2Center {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-between;
  background-color: #4F6FF5;
  width: 100%;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.mint {
  width: 381px;
  height: 150px;
}

.teamMain {
  flex-flow: row nowrap;
  display: flex;
  margin-top: 5%;
}

.t1 {
  color: white;
  font-size: 23px;
  text-shadow: 4px 3px 0 #3047345b;
}

.t2 {
  color: white;
  font-size: 20px;
}

.t3 {
  color: white;
  text-align: justify;
}

.teamPicDiv {
  margin-bottom: 2%;
}

.member {
  width: 50%;
  background-color: #273a2a;
  padding: 20px;
  border-color: white;
  border-style: groove;
  margin-left: 1%;
  margin-right: 1%;
}

.member2 {
  width: 50%;
  background-color: #273a2a;
  padding: 20px;
  border-color: white;
  border-style: groove;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
}

.totalSupply {
  text-align: center;
  font-size: 50px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  padding-bottom: 5px;
  text-shadow: 4px 3px 0 #383d6e3a;
  letter-spacing: 1px;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}



.footer {
  background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
  padding: 2%;
  position: static;

}

.footer2{
  position: static;
}

.copyright {
  color: rgba(255, 255, 255, 0.712);
  text-align: center;
  font-size: 15px;
  padding-bottom: 10px;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

.mainRM {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

}


.roadRm {
  width: 6%;
}

.roadmapMain {
  background-color: #273a2aa2;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 5%;
  border-radius: 10px;
}

.name {
  width: 53px;
  height: 60px;
}

.logoName2 {
  display: flex;
  flex-flow: row nowrap;
  margin-left: 5%;
}




/*Roadmap*/

.overallLines {
  justify-content: center;
  text-align: center;
  height: max-content;
}

.LeftRM {
  display: flex;
  flex-flow: row nowrap;
}

.lineRM {

  height: 200px;
  width: 10px;
  background-color: #13B2FD;
  margin-left: auto;
  margin-right: auto;
}

.topHorizontal {

  width: 100px;
  height: 10px;
  background-color: #bb4a08;


}

.contentRM {

  width: 25%;
  display: block;

  background-color: #0a9ec4;
  text-align: left;


}

.msg {
  color: white;
  font-size: 23px;
  text-align: center;
  padding-top: 2%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 0 10px #ffffff34;
  letter-spacing: 2px;
  font-family: 'Zilap Orion Personal Use', sans-serif;

}

.msg2 {
  color: white;
  font-size: 25px;
  text-align: center;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  text-shadow: 0 0 10px #ffffff34;
  letter-spacing: 2px;
  font-family: 'Zilap Orion Personal Use', sans-serif;

}

.gfont {
  font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  font-weight: bold;
}

.popUpMsg {
  height: 100vh;
  width: 100%;
  z-index: 1000;
  justify-content: center;
  background: rgb(61, 44, 129);
  background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
  position: fixed;
}

.popUpMsgPic {
  width: 30%;
}

.popUpMsgPicDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  animation: Slide_Down 10s ease infinite;

}

.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: fit-content;
  letter-spacing: 2px;
  font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;

}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

.button-29Div {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 2%;
}

.center {
  width: 180px;
  height: 60px;
  background-color: #0a9ec4;
  position: relative;

}

.btn {
  width: 180px;
  height: 60px;
  cursor: pointer;
  background: transparent;
  border: 1px solid #91C9FF;
  outline: none;
  transition: 1s ease-in-out;
}

svg {
  position: absolute;
  left: 0;
  top: 0;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 150 480;
  stroke-dashoffset: 150;
  transition: 1s ease-in-out;
}

.btn:hover {
  transition: 1s ease-in-out;
  background: #315f8d;
}

.btn:hover svg {
  stroke-dashoffset: -480;
}

.btn span {
  color: white;
  font-size: 18px;
  font-weight: 100;
}

.containerOpensea{

}

@keyframes Slide_Down {
  0% {
    transform: translateY(-2%);
    opacity: 1;
  }

  50% {
    transform: translateY(10%);
  }

  100% {
    transform: translateY(-2%);
  }

}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}
}

@media screen and (min-width: 1920px) and (max-width: 1920px) {


  
  .rarityCon1{
    font-size: 27px;
  }

  .rarityCon2{
    font-size: 27px;
  }

  .btn2Font{
    font-size: 40px;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
  
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  .faqH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 30px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-size: 30px;

  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 25px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 85%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 650px;
    height: 855px;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 27px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 560px;
    height: 747px;
  }
  
  .uR {
    width: 100%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
  }
  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
    font-size: 25px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 50px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 50px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;
  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
  }

  .discord{
    display: none;
  }
  
  .discord2:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 40%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
    
  }

  #fontSize{
    font-size: 27px;
  }
  
  
  .title {
    font-size: 60px;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    width: 30%;
  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 2%;
    position: static;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
  
  
 /*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg7_.jpg')no-repeat center center fixed;
}

  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 28px;
    text-align: center;
    padding-top: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 33px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 30%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
  
  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  }

@media screen and (min-width: 1921px) and (max-width: 3839px) {

    .rarityCon1{
      font-size: 27px;
    }
  
    .rarityCon2{
      font-size: 27px;
    }
  
    .btn2Font{
      font-size: 40px;
    }
  
    .osPic {
      width: 30px;
      height: 30px;
      cursor: pointer;
    
    }
    
    .price {
      text-align: center;
      padding: 15px;
      font-size: 20px;
      color: #ffffff;
    }
    
    .discord3 {
      padding-right: 20px;
      display: none;
    }
    
    
    .uRMobile {
      display: none;
    }
    
    .storyPicDivMobile {
      display: none;
    }
    
    .allWrap {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
      contain: content;
    }
    
    
    .boxWrap2 {
      display: flex;
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
      background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
      background-size: cover;
    
    }
    
    .boxWrap5 {
      display: flex;
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
    
      background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    
    .boxWrap5 {
      display: flex;
      flex-flow: column nowrap;
      padding-top: 5%;
    }
    
    .boxWrap3 {
      width: 100%;
      padding-top: 5%;
      padding-bottom: 5%;
      background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
      background-size: cover;
    }
    
    .boxWrap4 {
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 5%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
    
    .storyPicDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }
    
    .UtilDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
    }
    
    .storyH {
      font-size: 70px;
      font-weight: bolder;
      letter-spacing: 20px;
      background: #13B2FD;
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
      -webkit-background-clip: text;
      font-family: 'Zilap Orion Personal Use', sans-serif;
      -webkit-text-fill-color: transparent;
      margin-bottom: 2%;
    }

    .rmH {
      font-size: 70px;
      font-weight: bolder;
      letter-spacing: 20px;
      background: #13B2FD;
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
      -webkit-background-clip: text;
      font-family: 'Zilap Orion Personal Use', sans-serif;
      -webkit-text-fill-color: transparent;
      margin-bottom: 2%;
    }
    
    .faqH {
      font-size: 70px;
      font-weight: bolder;
      letter-spacing: 20px;
      background: #13B2FD;
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
      -webkit-background-clip: text;
      font-family: 'Zilap Orion Personal Use', sans-serif;
      -webkit-text-fill-color: transparent;
      text-align: center;
    }
    
    .utilH {
      font-size: 70px;
      font-weight: bolder;
      letter-spacing: 20px;
      background: #13B2FD;
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
      -webkit-background-clip: text;
      font-family: 'Zilap Orion Personal Use', sans-serif;
      -webkit-text-fill-color: transparent;
      text-align: center;
      margin-bottom: 5px;
    }
    
    .rarityAndNum {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 30px;
      margin-top: 10px;
    }
    
    .rarityAndNum div {
      font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    }
    
    .rarityAndNum1 div {
      font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
      font-size: 30px;
  
    }
    
    .rarityAndNum1 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 25px;
    }
    
    .rarityBg {
      background-color: rgb(134, 147, 151);
      padding: 20px;
      width: 85%;
      border-bottom-left-radius: 50px;
      border-top-right-radius: 50px;
      margin-top: 10%;
      color: rgba(255, 255, 255, 0.753);
    
      /* From https://css.glass */
    background: rgba(255, 255, 255, 0.164);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    }
    
    .picSet {
      width: 650px;
      height: 855px;
    }
    
    .roadmapH {
      font-size: 100px;
      text-shadow: 4px 3px 0 #3a5a3f;
      color: white;
      margin-bottom: 10px;
      font-family: 'KARASHA', sans-serif;
      text-align: center;
    }
    
    .storyCon {
      color: white;
      font-size: 27px;
      letter-spacing: 3px;
      font-weight: 100;
    }
    
    .storyPic {
      width: 400px;
      height: 600px;
    
    }
    
    .storyRight {
      width: 80%;
      margin-right: auto;
      margin-left: auto;
      justify-content: center;
      text-align: center;
    }
    
    .ggPic {
      width: 560px;
      height: 747px;
    }
    
    .uR {
      width: 80%;
      flex-flow: row nowrap;
      display: flex;
      justify-content: space-between;
      margin-top: 3%;
      margin-left: auto;
      margin-right: auto;
    }
    
    .rarityBg2 {
      flex-flow: column nowrap;
    }
    
    .rarityCon {
      flex-flow: column nowrap;
      color: white;
      font-size: 20px;
      letter-spacing: 3px;
    }
    
    
    /* CSS */
    .button-87 {
      margin-top: 5%;
      padding: 15px 60px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.5s;
      background-size: 200% auto;
      color: white;
      border-radius: 10px;
      display: block;
      border: 0px;
      box-shadow: 0px 0px 14px -7px #f09819;
      background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
      cursor: pointer;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      letter-spacing: 3px;
      font-size: 25px;
    }
    
    .button-87:hover {
      background-position: right center;
      /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
    
    .button-87:active {
      transform: scale(0.95);
    }
    
    .rarityBtn::after {
      left: -100%;
      clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
      background-color: rgba(255, 255, 255, 0.6);
      transition: all 300ms ease;
    
    }
    
    
    .rarityBtn:hover:after {
      left: 100%;
    
    }
    
    .oPSubTitle {
      color: white;
      font-size: 50px;
      background-color: #0A0068;
      text-align: center;
    }
    
    .oPSubTitle2 {
      color: white;
      font-size: 50px;
      background-color: #0a006800;
      text-align: center;
    }
    
    .container {
      position: relative;
      width: fit-content;
    
    }
    
    .image {
      display: block;
      width: 100%;
      height: auto;
    }
    
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      background: #13B2FD;
      background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
    }
    
    .container:hover .overlay {
      opacity: 1;
    }
    
    .text {
      color: white;
      font-size: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
      letter-spacing: 2px;
    }
    
    
    .utilRow {
      flex-flow: row nowrap;
      display: flex;
      justify-content: space-between;
      margin-top: 1%;
    }
    
    .utilRow2 {
      flex-flow: row nowrap;
      display: flex;
      justify-content: space-between;
      margin-top: 5%;
    }
    
    .bgUtilMain {
      background-color: #e0706c;
      width: 60%;
    }
    
    .ninja {
      width: 220px;
      height: 522px;
    }
    
    .ninja2 {
      width: 250px;
      height: 400px;
    }
    
    .ninjaDiv {
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      display: flex;
    }
    
    .bgUtil {
      width: 70%;
      background-color: #273a2a;
      padding: 20px;
      border-radius: 20px;
      border-style: groove;
      border-color: white;
      border-width: 2px;
    }
    
    .bgUtil2 {
      width: 70%;
      background-color: #273a2a;
      padding: 20px;
      margin-top: 3%;
      border-radius: 20px;
      border-style: groove;
      border-color: white;
      border-width: 2px;
    }
    
    .intro {
      color: white;
      font-size: 150px;
      text-align: center;
      text-shadow: 4px 3px 0 #273a2a;
      font-family: 'KARASHA', sans-serif;
    
    }
    
    .intro2 {
      color: rgba(255, 255, 255, 0.705);
      font-size: 20px;
      text-align: center;
      text-shadow: 4px 3px 0 #383d6e18;
    }
    
    .mintDiv {
      padding-top: 3%;
      padding-bottom: 3%;
      width: 40%;
      margin-left: auto;
      margin-right: auto;
      background: rgba(255, 255, 255, 0.055);
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
      border-color: white;
      border-width: 2px;
      border-style: solid;
    }
    
    .errorMessage {
      margin-left: 3%;
      margin-top: 1%;
      font-size: 15px;
      color: #e0706c;
      text-align: center;
    
    }
    
    .loadingContainer {
      margin-top: 1%;
    }
    
    .loadingText {
      font-size: 20px;
      text-align: center;
      color: rgb(204, 204, 204);
    }
    
    .loadTextSub {
      text-align: center;
      color: rgb(185, 185, 185);
      font-size: 13px;
      padding-top: 5px;
    }
    
    /* loading dots */
    
    
    .loadingText:after {
      content: '.';
      animation: dots 1s steps(5, end) infinite;
    }
    
    @keyframes dots {
    
      0%,
      20% {
        color: rgba(0, 0, 0, 0);
        text-shadow:
          .25em 0 0 rgba(0, 0, 0, 0),
          .5em 0 0 rgba(0, 0, 0, 0);
      }
    
      40% {
        color: rgb(151, 151, 151);
        text-shadow:
          .25em 0 0 rgba(0, 0, 0, 0),
          .5em 0 0 rgba(0, 0, 0, 0);
      }
    
      60% {
        text-shadow:
          .25em 0 0 rgb(124, 124, 124),
          .5em 0 0 rgba(0, 0, 0, 0);
      }
    
      80%,
      100% {
        text-shadow:
          .25em 0 0 rgb(121, 121, 121),
          .5em 0 0 rgb(121, 121, 121);
      }
    }
    
    .wpPic {
      width: 500px;
      height: 500px;
      margin-left: 10%;
    
    }
    
    .wPMobile {
      display: none;
    }
    
    .introductionMobile {
      display: none
    }
    
    .hl {
      color: #6ce34d;
    }
    
    .right {
      flex-flow: row nowrap;
      display: flex;
      padding-bottom: 20px;
      padding-top: 20px;
      justify-content: space-evenly;
    }
    
    .right2 {
      flex-flow: row nowrap;
      display: flex;
      padding-bottom: 1.5%;
      margin-left: auto;
      margin-right: auto;
      width: 10%;
      justify-content: space-between;
    }
    
    .logoB {
      width: 53px;
      height: 60px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    
    .logoBDiv {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      display: flex;
      padding-bottom: 13px;
    
    }
    
    .discord2 {
      padding-right: 20px;
      transition: transform .2s;
    }
  
    .discord{
      display: none;
    }
    
    .discord2:hover {
      transform: scale(1.1);
    }
    
    
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
      display: none;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #3b3b3b;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    }
    
    .note {
      color: rgb(255, 255, 255);
    }
    
    .twitterSoc {
      margin-top: 10px;
      margin-right: 5px;
      /* z-index: -2;*/
      margin-left: 5px;
    
    }
    
    .discordSoc {
      margin-top: 10px;
      margin-right: 5px;
      margin-left: 5px;
      /*  z-index: -2;*/
    
    }
    
    /*.website{
      margin-top: 10px;
      margin-left: 10px;
     /* z-index: -2;*/
    /*color: #ffffffa2;
    }*/
    .soc {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 20px;
    
    }
    
    
    
    
    
    .wallet {
      background-color: rgba(53, 52, 52, 0);
      border-style: solid;
      color: white;
      padding: 15px 25px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 15px;
      border-radius: 25px;
      font-family: 'Poppins', sans-serif;
      box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
      padding-left: 50px;
      padding-right: 50px;
    
      border: 2px solid rgb(73, 81, 190);
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
    
    
    }
    
    .wallet:hover {
      background-color: white;
      color: rgb(73, 81, 190);
    }
    
    .connect {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-family: 'Poppins', sans-serif;
      padding-top: 5px;
      margin-left: 10%;
    
    }
    
    .connect2 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-family: 'Poppins', sans-serif;
      padding-top: 5px;
      margin-right: 2%;
    }
    
    .walletAndSocial {
      display: flex;
      justify-content: space-between;
      width: 36%;
    }
    
    .connect div {
      margin-left: 10px;
      margin-right: 10px;
      font-family: 'Poppins', sans-serif;
      color: white;
      padding-top: 15px;
      font-size: 16px;
    
    
    }
    
    .connect div:hover {
      margin-left: 10px;
      margin-right: 10px;
      font-family: 'Poppins', sans-serif;
      color: rgb(184, 184, 184);
      padding-top: 15px;
      cursor: pointer;
    
    }
    
    
    @keyframes glow {
      from {
        box-shadow: 0 0 10px -10px rgb(255, 219, 60);
      }
    
      to {
        box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
      }
    }
    
    
    
    
    .nftblockWalletConnectedALL {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      padding-bottom: 50px;
      padding-top: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    
    .minting_count_button {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
    
    }
    
    .mintbuttondiv {
      margin-top: 20px;
      text-align: center;
    }
    
    .nftblockWalletConnected {
      display: flex;
      margin-bottom: 10px;
    }
    
    .btnfos-0-2 {
      margin-top: 20px;
      font-size: 20px;
      margin-left: 20px;
      margin-right: 20px;
      padding: 10px;
      width: 50px;
      border: none;
      border-radius: 10px;
      box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
      cursor: pointer;
    }
    
    .btnfos-0-2-2 {
      margin-top: 20px;
      font-size: 20px;
      margin-left: 20px;
      margin-right: 20px;
      padding: 10px;
      width: 50px;
      border: none;
      border-radius: 10px;
      box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
      cursor: pointer;
    
    }
    
    
    .btnfos-0-2:hover:active {
      background-color: #d3d3d3;
      box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
    }
    
    
    .btnfos-0-2-2:hover:active {
      background-color: #d3d3d3;
      box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
    }
    
    
    .btnfos-0-3 {
      margin-top: 20px;
      font-size: 25px;
      margin-right: auto;
      margin-left: auto;
      justify-content: center;
      padding: 10px;
      font-weight: bold;
      border: none;
      border-radius: 5px;
      width: 200px;
      box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
    }
    
    
    .btnfos-0-3:hover {
    
      color: rgb(78, 78, 78);
      cursor: pointer;
    
    }
    
    .btnfos-0-3:hover:active {
      background-color: #d3d3d3;
      box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
    }
    
    .nftamount {
    
      color: #08bb52;
      font-size: 60px;
      font-family: 'Titan One', cursive;
      text-shadow: 4px 3px 0 #383d6e17;
    }
    
    .walletConnect {
      display: flex;
      justify-content: center;
    }
    
    .walletConnectOs {
      display: flex;
      justify-content: center;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
    }
    
    .connectButton {
    
      font-size: 45px;
    }
    
    .connectButton:hover {
      color: darkcyan;
      cursor: pointer;
    
    }
    
    .loadingContainer {
      text-align: center;
      padding-top: 20px;
    }
    
    .errorMessage {
      text-align: center;
      padding-top: 20px;
    }
    
    .successfully {
      text-align: center;
      padding-top: 20px;
      color: rgb(124, 199, 74);
    }
    
    
    .headers {
      display: flex;
      flex-flow: column nowrap;
      text-align: center;
      justify-content: center;
      color: black;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1%;
    }
    
    .navBar {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: 40%;
      color: #ffffff;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
      text-align: center;
      font-family: 'Rubik', sans-serif;
    }
    
    .rows:hover {
      color: #ffffffb2;
      
    }
  
    #fontSize{
      font-size: 27px;
    }
    
    
    .title {
      font-size: 60px;
      letter-spacing: 20px;
      background: #13B2FD;
      background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
      -webkit-background-clip: text;
      font-family: 'Zilap Orion Personal Use', sans-serif;
      -webkit-text-fill-color: transparent;
      text-align: center;
      margin-top: 2%;
    }
    
    .socialContainer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 8%;
      margin-top: auto;
      margin-bottom: auto;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2%;
    }
    
    .row1 {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: auto;
      width: 100%;
      color: white;
    }
    
    .introduction {
    
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    
    
    .wallet2 {
      background-color: #ffffff;
      border-style: solid;
      color: rgb(0, 0, 0);
      padding: 13px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 17px;
      border-radius: 10px;
      font-family: 'Poppins', sans-serif;
      padding-left: 30px;
      padding-right: 30px;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      transition: transform .2s;
    }
    
    .wallet2:hover {
      color: #62a06b;
    }
    
    .wallet2Btn {
      background-color: rgba(53, 52, 52, 0);
      border-style: solid;
      color: white;
      padding: 15px 25px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 12px;
      border-radius: 25px;
      font-family: 'Poppins', sans-serif;
      box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
      width: 400px;
      border: 2px solid #ffffff;
      margin-left: 10px;
      margin-right: 10px;
      border: 2px solid #2E8B57;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
    }
    
    .wallet2Btn:hover {
      color: rgb(156, 156, 156);
      animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      perspective: 1000px;
    }
    
    
    .wallet3 {
      margin-left: auto;
      margin-right: auto;
      background-color: rgb(73, 87, 165);
      box-shadow: rgb(35, 42, 78) 0px 5px;
      border: none;
      color: white;
      font-size: 35px;
      border: none;
      border-radius: 10px;
      font-weight: bold;
      padding: 10px;
      width: 250px;
    }
    
    
    .walletOS {
      margin-left: auto;
      margin-right: auto;
      background-color: rgb(73, 87, 165);
      box-shadow: rgb(35, 42, 78) 0px 5px;
      border: none;
      color: white;
      font-size: 35px;
      border: none;
      border-radius: 10px;
      font-weight: bold;
      padding: 10px;
      width: 250px;
    
    }
    
    .walletOS:hover {
      animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      perspective: 1000px;
    }
    
    .mintAndOS {
      display: flex;
      justify-content: space-between;
      flex-flow: row nowrap;
      padding-bottom: 50px;
      padding-top: 50px;
      margin-left: auto;
      margin-right: auto;
    
    }
    
    .mintAndOS2 {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      justify-content: space-between;
      width: 20%;
    }
    
    .btn2Center {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      flex-flow: row nowrap;
      display: flex;
      justify-content: space-between;
      background-color: #4F6FF5;
      width: 100%;
    }
    
    .wallet3:hover {
      animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
      transform: translate3d(0, 0, 0);
      perspective: 1000px;
    }
    
    .mint {
      width: 381px;
      height: 150px;
    }
    
    .teamMain {
      flex-flow: row nowrap;
      display: flex;
      margin-top: 5%;
    }
    
    .t1 {
      color: white;
      font-size: 23px;
      text-shadow: 4px 3px 0 #3047345b;
    }
    
    .t2 {
      color: white;
      font-size: 20px;
    }
    
    .t3 {
      color: white;
      text-align: justify;
    }
    
    .teamPicDiv {
      margin-bottom: 2%;
    }
    
    .member {
      width: 50%;
      background-color: #273a2a;
      padding: 20px;
      border-color: white;
      border-style: groove;
      margin-left: 1%;
      margin-right: 1%;
    }
    
    .member2 {
      width: 50%;
      background-color: #273a2a;
      padding: 20px;
      border-color: white;
      border-style: groove;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
    }
    
    @keyframes shake {
    
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
    
      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
    
    @keyframes slidebg {
      to {
        background-position: 20vw;
      }
    }
    
    .in2 {
      width: 100%;
    }
    
    .totalSupply {
      text-align: center;
      font-size: 50px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      color: white;
      padding-bottom: 5px;
      text-shadow: 4px 3px 0 #383d6e3a;
      letter-spacing: 1px;
    }
    
    @keyframes Slide_Up {
      0% {
        transform: translateY(250px);
        opacity: 1;
      }
    
      10%,
      87.5% {
        transform: translateY(0);
      }
    
      90% {
        opacity: 1;
      }
    
      95% {
        opacity: 0;
      }
    
      100% {
        transform: translateY(250px);
        opacity: 0;
      }
    
    }
    
    @keyframes Slide_Up_Tag {
      0% {
        transform: translateY(250px);
        opacity: 0;
      }
    
      10% {
        opacity: 1;
      }
    
      20%,
      92.5% {
        transform: translateY(0);
      }
    
      90% {
        opacity: 1;
      }
    
      95% {
        opacity: 0;
      }
    
      100% {
        transform: translateY(250px);
        opacity: 0;
      }
    
    }
    
    @keyframes Slide_Up_Buttons {
      0% {
        transform: translateY(250px);
        opacity: 0;
      }
    
      10% {
        opacity: 0;
      }
    
      20% {
        opacity: 1;
      }
    
      25%,
      95% {
        transform: translateY(0);
      }
    
      90% {
        opacity: 1;
      }
    
      95% {
        opacity: 0;
      }
    
      100% {
        transform: translateY(250px);
        opacity: 0;
      }
    
    }
    
    
    
    .footer {
      background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
      padding: 2%;
      position: static;
    }
    
    .copyright {
      color: rgba(255, 255, 255, 0.712);
      text-align: center;
      font-size: 15px;
      padding-bottom: 10px;
    }
    
    .btn3 {
      flex-flow: row nowrap;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    
    .link {
      text-align: center;
      font-size: 15px;
      margin-top: 5px;
    }
    
    .mainRM {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    
    }
    
    
    .roadRm {
      width: 6%;
    }
    
    .roadmapMain {
      background-color: #273a2aa2;
      padding-top: 1%;
      padding-bottom: 1%;
      padding-left: 5%;
      border-radius: 10px;
    }
    
    .name {
      width: 53px;
      height: 60px;
    }
    
    .logoName2 {
      display: flex;
      flex-flow: row nowrap;
      margin-left: 5%;
    }
    
    
    
    /*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 50%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg7_.jpg')no-repeat center center fixed;
}

    
    /*Roadmap*/
    
    .overallLines {
      justify-content: center;
      text-align: center;
      height: max-content;
    }
    
    .LeftRM {
      display: flex;
      flex-flow: row nowrap;
    }
    
    .lineRM {
    
      height: 200px;
      width: 10px;
      background-color: #13B2FD;
      margin-left: auto;
      margin-right: auto;
    }
    
    .topHorizontal {
    
      width: 100px;
      height: 10px;
      background-color: #bb4a08;
    
    
    }
    
    .contentRM {
    
      width: 25%;
      display: block;
    
      background-color: #0a9ec4;
      text-align: left;
    
    
    }
    
    .msg {
      color: white;
      font-size: 35px;
      text-align: center;
      padding-top: 2%;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      text-shadow: 0 0 10px #ffffff34;
      letter-spacing: 2px;
      font-family: 'Zilap Orion Personal Use', sans-serif;
    
    }
    
    .msg2 {
      color: white;
      font-size: 40px;
      text-align: center;
      margin-top: 2%;
      margin-left: auto;
      margin-right: auto;
      text-shadow: 0 0 10px #ffffff34;
      letter-spacing: 2px;
      font-family: 'Zilap Orion Personal Use', sans-serif;
    
    }
    
    .gfont {
      font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
      font-weight: bold;
    }
    
    .popUpMsg {
      height: 100vh;
      width: 100%;
      z-index: 1000;
      justify-content: center;
      background: rgb(61, 44, 129);
      background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
      position: fixed;
    }
    
    .popUpMsgPic {
      width: 30%;
    }
    
    .popUpMsgPicDiv {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      display: flex;
      animation: Slide_Down 10s ease infinite;
    
    }
    
    .button-29 {
      align-items: center;
      appearance: none;
      background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
      border: 0;
      border-radius: 6px;
      box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: inline-flex;
      height: 48px;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: center;
      text-decoration: none;
      transition: box-shadow .15s, transform .15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow, transform;
      font-size: 32px;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      width: fit-content;
      letter-spacing: 2px;
      font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .button-29:focus {
      box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    }
    
    .button-29:hover {
      box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
      transform: translateY(-2px);
    }
    
    .button-29:active {
      box-shadow: #3c4fe0 0 3px 7px inset;
      transform: translateY(2px);
    }
    
    .button-29Div {
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      display: flex;
      margin-top: 2%;
    }
    
    .center {
      width: 180px;
      height: 60px;
      background-color: #0a9ec4;
      position: relative;
    
    }
    
    .btn {
      width: 180px;
      height: 60px;
      cursor: pointer;
      background: transparent;
      border: 1px solid #91C9FF;
      outline: none;
      transition: 1s ease-in-out;
    }
    
    svg {
      position: absolute;
      left: 0;
      top: 0;
      fill: none;
      stroke: #fff;
      stroke-dasharray: 150 480;
      stroke-dashoffset: 150;
      transition: 1s ease-in-out;
    }
    
    .btn:hover {
      transition: 1s ease-in-out;
      background: #315f8d;
    }
    
    .btn:hover svg {
      stroke-dashoffset: -480;
    }
    
    .btn span {
      color: white;
      font-size: 18px;
      font-weight: 100;
    }
    
    .containerOpensea{
    
    }
    
    @keyframes Slide_Down {
      0% {
        transform: translateY(-2%);
        opacity: 1;
      }
    
      50% {
        transform: translateY(10%);
      }
    
      100% {
        transform: translateY(-2%);
      }
    
    }
    
    @keyframes shake {
    
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }
    
      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }
    
      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }
    
      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
    
    @keyframes Slide_Left {
      0% {
        transform: translateX(250px);
      }
    
      100% {
        transform: translateX(0);
      }
    }
    
    @keyframes Slide_Left_Right {
    
      0%,
      100% {
        transform: translateX(100px);
      }
    
      12.5%,
      75% {
        transform: translateX(0);
      }
    
      25% {
        transform: translateX(20px);
      }
    
    }
    
    @keyframes Opacity {
    
      0%,
      100% {
        opacity: 0;
      }
    
      25%,
      75% {
        opacity: 1;
      }
    
    
    }
    
    @keyframes glow {
      from {
        box-shadow: 0 0 10px -10px rgb(127, 212, 48);
      }
    
      to {
        box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
      }
    }
    
    @keyframes scaleIn {
      from {
        transform: scale(.5, .5);
        opacity: .5;
      }
    
      to {
        transform: scale(2.5, 2.5);
        opacity: 0;
      }
    }
    
    @keyframes zoominoutsinglefeatured {
      0% {
        transform: scale(1, 1);
      }
    
      50% {
        transform: scale(1.05, 1.05);
      }
    
      100% {
        transform: scale(1, 1);
      }
    }
}  

@media screen and (min-width: 3840px) {

  .rarityCon1{
    font-size: 27px;
  }

  .rarityCon2{
    font-size: 27px;
  }

  .btn2Font{
    font-size: 40px;
  }

  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
  
  }
  
  .price {
    text-align: center;
    padding: 15px;
    font-size: 20px;
    color: #ffffff;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    background: radial-gradient(ellipse at bottom, #0b1016 0%, #090a0f 100%);
    contain: content;
  }
  
  
  .boxWrap2 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg1.jpg');
    background-size: cover;
  
  }
  
  .boxWrap5 {
    display: flex;
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
  
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg2.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap5 {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
  }
  
  .boxWrap3 {
    width: 100%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg5.jpg');
    background-size: cover;
  }
  
  .boxWrap4 {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9023984593837535) 0%, rgba(0, 0, 0, 0.5018382352941176) 43%, rgba(0, 0, 0, 0.43461134453781514) 53%, rgba(0, 0, 0, 0.908000700280112) 99%), url('../../assets/galaxybg4.jpg')no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }

  .rmH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    margin-bottom: 2%;
  }
  
  .faqH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
  }
  
  .utilH {
    font-size: 70px;
    font-weight: bolder;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 5px;
  }
  
  .rarityAndNum {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 30px;
    margin-top: 10px;
  }
  
  .rarityAndNum div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
  }
  
  .rarityAndNum1 div {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-size: 30px;

  }
  
  .rarityAndNum1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 25px;
  }
  
  .rarityBg {
    background-color: rgb(134, 147, 151);
    padding: 20px;
    width: 85%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    margin-top: 10%;
    color: rgba(255, 255, 255, 0.753);
  
    /* From https://css.glass */
  background: rgba(255, 255, 255, 0.164);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  }
  
  .picSet {
    width: 650px;
    height: 855px;
  }
  
  .roadmapH {
    font-size: 100px;
    text-shadow: 4px 3px 0 #3a5a3f;
    color: white;
    margin-bottom: 10px;
    font-family: 'KARASHA', sans-serif;
    text-align: center;
  }
  
  .storyCon {
    color: white;
    font-size: 27px;
    letter-spacing: 3px;
    font-weight: 100;
  }
  
  .storyPic {
    width: 400px;
    height: 600px;
  
  }
  
  .storyRight {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
  }
  
  .ggPic {
    width: 560px;
    height: 747px;
  }
  
  .uR {
    width: 60%;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 3%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .rarityBg2 {
    flex-flow: column nowrap;
  }
  
  .rarityCon {
    flex-flow: column nowrap;
    color: white;
    font-size: 20px;
    letter-spacing: 3px;
  }
  
  
  /* CSS */
  .button-87 {
    margin-top: 5%;
    padding: 15px 60px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    border-radius: 10px;
    display: block;
    border: 0px;
    box-shadow: 0px 0px 14px -7px #f09819;
    background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    letter-spacing: 3px;
    font-size: 25px;
  }
  
  .button-87:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }
  
  .button-87:active {
    transform: scale(0.95);
  }
  
  .rarityBtn::after {
    left: -100%;
    clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
    background-color: rgba(255, 255, 255, 0.6);
    transition: all 300ms ease;
  
  }
  
  
  .rarityBtn:hover:after {
    left: 100%;
  
  }
  
  .oPSubTitle {
    color: white;
    font-size: 50px;
    background-color: #0A0068;
    text-align: center;
  }
  
  .oPSubTitle2 {
    color: white;
    font-size: 50px;
    background-color: #0a006800;
    text-align: center;
  }
  
  .container {
    position: relative;
    width: fit-content;
  
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background: #13B2FD;
    background: linear-gradient(to bottom, #075072 0%, #4059bd 68%, #8037EE 100%);
  }
  
  .container:hover .overlay {
    opacity: 1;
  }
  
  .text {
    color: white;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    letter-spacing: 2px;
  }
  
  
  .utilRow {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 1%;
  }
  
  .utilRow2 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
  }
  
  .bgUtilMain {
    background-color: #e0706c;
    width: 60%;
  }
  
  .ninja {
    width: 220px;
    height: 522px;
  }
  
  .ninja2 {
    width: 250px;
    height: 400px;
  }
  
  .ninjaDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .bgUtil {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .bgUtil2 {
    width: 70%;
    background-color: #273a2a;
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    border-style: groove;
    border-color: white;
    border-width: 2px;
  }
  
  .intro {
    color: white;
    font-size: 150px;
    text-align: center;
    text-shadow: 4px 3px 0 #273a2a;
    font-family: 'KARASHA', sans-serif;
  
  }
  
  .intro2 {
    color: rgba(255, 255, 255, 0.705);
    font-size: 20px;
    text-align: center;
    text-shadow: 4px 3px 0 #383d6e18;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.055);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border-color: white;
    border-width: 2px;
    border-style: solid;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 15px;
    color: #e0706c;
    text-align: center;
  
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 20px;
    text-align: center;
    color: rgb(204, 204, 204);
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(185, 185, 185);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
    justify-content: space-evenly;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-left: auto;
    margin-right: auto;
    width: 5%;
    justify-content: space-between;
  }
  
  .logoB {
    width: 53px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .logoBDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    padding-bottom: 13px;
  
  }
  
  .discord2 {
    padding-right: 20px;
    transition: transform .2s;
  }

  .discord{
    display: none;
  }
  
  .discord2:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
  }
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-left: 10%;
  
  }
  
  .connect2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    padding-top: 5px;
    margin-right: 2%;
  }
  
  .walletAndSocial {
    display: flex;
    justify-content: space-between;
    width: 36%;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: white;
    padding-top: 15px;
    font-size: 16px;
  
  
  }
  
  .connect div:hover {
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Poppins', sans-serif;
    color: rgb(184, 184, 184);
    padding-top: 15px;
    cursor: pointer;
  
  }
  
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  
  }
  
  .mintbuttondiv {
    margin-top: 20px;
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) -5px 5px;
    cursor: pointer;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    width: 50px;
    border: none;
    border-radius: 10px;
    box-shadow: rgba(94, 94, 94, 0.288) 5px 5px;
    cursor: pointer;
  
  }
  
  
  .btnfos-0-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) -5px 5px;
  }
  
  
  .btnfos-0-2-2:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(134, 134, 134, 0.288) 5px 5px;
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    font-size: 25px;
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    padding: 10px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    width: 200px;
    box-shadow: rgba(94, 94, 94, 0.288) 0px 5px;
  }
  
  
  .btnfos-0-3:hover {
  
    color: rgb(78, 78, 78);
    cursor: pointer;
  
  }
  
  .btnfos-0-3:hover:active {
    background-color: #d3d3d3;
    box-shadow: rgba(141, 141, 141, 0.288) 0px 5px;
  }
  
  .nftamount {
  
    color: #08bb52;
    font-size: 60px;
    font-family: 'Titan One', cursive;
    text-shadow: 4px 3px 0 #383d6e17;
  }
  
  .walletConnect {
    display: flex;
    justify-content: center;
  }
  
  .walletConnectOs {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: rgb(124, 199, 74);
  }
  
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    justify-content: center;
    color: black;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1%;
  }
  
  .navBar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    width: 15%;
    color: #ffffff;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
    font-family: 'Rubik', sans-serif;
  }
  
  .rows:hover {
    color: #ffffffb2;
    
  }

  #fontSize{
    font-size: 27px;
  }
  
  
  .title {
    font-size: 60px;
    letter-spacing: 20px;
    background: #13B2FD;
    background: linear-gradient(to bottom, #13B2FD 0%, #4F6FF5 68%, #8037EE 100%);
    -webkit-background-clip: text;
    font-family: 'Zilap Orion Personal Use', sans-serif;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 2%;
  }
  
  .socialContainer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 8%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
  }
  
  .row1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    color: white;
  }
  
  .introduction {
  
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  
  .wallet2 {
    background-color: #ffffff;
    border-style: solid;
    color: rgb(0, 0, 0);
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 17px;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    transition: transform .2s;
  }
  
  .wallet2:hover {
    color: #62a06b;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
    font-family: 'Poppins', sans-serif;
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  }
  
  
  .walletOS {
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(73, 87, 165);
    box-shadow: rgb(35, 42, 78) 0px 5px;
    border: none;
    color: white;
    font-size: 35px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
    padding: 10px;
    width: 250px;
  
  }
  
  .walletOS:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mintAndOS {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    padding-bottom: 50px;
    padding-top: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .mintAndOS2 {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    width: 15%;
  }
  
  .btn2Center {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    background-color: #4F6FF5;
    width: 100%;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .mint {
    width: 381px;
    height: 150px;
  }
  
  .teamMain {
    flex-flow: row nowrap;
    display: flex;
    margin-top: 5%;
  }
  
  .t1 {
    color: white;
    font-size: 23px;
    text-shadow: 4px 3px 0 #3047345b;
  }
  
  .t2 {
    color: white;
    font-size: 20px;
  }
  
  .t3 {
    color: white;
    text-align: justify;
  }
  
  .teamPicDiv {
    margin-bottom: 2%;
  }
  
  .member {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: 1%;
    margin-right: 1%;
  }
  
  .member2 {
    width: 50%;
    background-color: #273a2a;
    padding: 20px;
    border-color: white;
    border-style: groove;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    padding-bottom: 5px;
    text-shadow: 4px 3px 0 #383d6e3a;
    letter-spacing: 1px;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  
  
  .footer {
    background: linear-gradient(0deg, rgba(19, 14, 41, 0.253) 0%, rgba(38, 29, 87, 0.192) 59%, rgba(49, 41, 94, 0.226) 100%);
    padding: 2%;
    position: static;
  }
  
  .copyright {
    color: rgba(255, 255, 255, 0.712);
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  .mainRM {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  
  }
  
  
  .roadRm {
    width: 6%;
  }
  
  .roadmapMain {
    background-color: #273a2aa2;
    padding-top: 1%;
    padding-bottom: 1%;
    padding-left: 5%;
    border-radius: 10px;
  }
  
  .name {
    width: 53px;
    height: 60px;
  }
  
  .logoName2 {
    display: flex;
    flex-flow: row nowrap;
    margin-left: 5%;
  }
  
  
  
     /*.............. FAQ ..............*/


summary {
  font-size: 20px;
  font-weight: 600;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  text-align: left;
  cursor: pointer;

  border: 1px solid #70d2ec;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px #1b5eb3;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 40%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.faq__content {

  color: rgba(255, 255, 255, 0.829);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 50px;
  font-weight: bolder;
  color: white;
  font-family: "poppins";
  text-shadow: 0 0 10px #ffffff96;

}

.faqbg {
  width: 100vw;
  text-align: left;
  padding: 2px;
  padding-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;
  padding-top: 5%;
  padding-bottom: 10%;
  background: linear-gradient(0deg, rgba(0,0,0,0.9108018207282913) 2%, rgba(0,0,0,0.3113620448179272) 23%, rgba(0,0,0,0.12088585434173671) 68%, rgba(0,0,0,0.9976365546218487) 100%), url('../../assets/galaxybg7_.jpg')no-repeat center center fixed;
}

  /*Roadmap*/
  
  .overallLines {
    justify-content: center;
    text-align: center;
    height: max-content;
  }
  
  .LeftRM {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .lineRM {
  
    height: 200px;
    width: 10px;
    background-color: #13B2FD;
    margin-left: auto;
    margin-right: auto;
  }
  
  .topHorizontal {
  
    width: 100px;
    height: 10px;
    background-color: #bb4a08;
  
  
  }
  
  .contentRM {
  
    width: 25%;
    display: block;
  
    background-color: #0a9ec4;
    text-align: left;
  
  
  }
  
  .msg {
    color: white;
    font-size: 35px;
    text-align: center;
    padding-top: 2%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .msg2 {
    color: white;
    font-size: 40px;
    text-align: center;
    margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
    text-shadow: 0 0 10px #ffffff34;
    letter-spacing: 2px;
    font-family: 'Zilap Orion Personal Use', sans-serif;
  
  }
  
  .gfont {
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    font-weight: bold;
  }
  
  .popUpMsg {
    height: 100vh;
    width: 100%;
    z-index: 1000;
    justify-content: center;
    background: rgb(61, 44, 129);
    background: radial-gradient(circle, rgba(61, 44, 129, 1) 1%, rgba(19, 10, 61, 1) 40%, rgba(11, 6, 34, 1) 71%);
    position: fixed;
  }
  
  .popUpMsgPic {
    width: 30%;
  }
  
  .popUpMsgPicDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    animation: Slide_Down 10s ease infinite;
  
  }
  
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 32px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: fit-content;
    letter-spacing: 2px;
    font-family: 'GALACTIC VANGUARDIAN NCV', sans-serif;
    padding-top: 35px;
    padding-bottom: 35px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .button-29Div {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 2%;
  }
  
  .center {
    width: 180px;
    height: 60px;
    background-color: #0a9ec4;
    position: relative;
  
  }
  
  .btn {
    width: 180px;
    height: 60px;
    cursor: pointer;
    background: transparent;
    border: 1px solid #91C9FF;
    outline: none;
    transition: 1s ease-in-out;
  }
  
  svg {
    position: absolute;
    left: 0;
    top: 0;
    fill: none;
    stroke: #fff;
    stroke-dasharray: 150 480;
    stroke-dashoffset: 150;
    transition: 1s ease-in-out;
  }
  
  .btn:hover {
    transition: 1s ease-in-out;
    background: #315f8d;
  }
  
  .btn:hover svg {
    stroke-dashoffset: -480;
  }
  
  .btn span {
    color: white;
    font-size: 18px;
    font-weight: 100;
  }
  
  .containerOpensea{
  
  }
  
  @keyframes Slide_Down {
    0% {
      transform: translateY(-2%);
      opacity: 1;
    }
  
    50% {
      transform: translateY(10%);
    }
  
    100% {
      transform: translateY(-2%);
    }
  
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
}  