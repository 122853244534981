@media screen and (max-width: 499px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 500px) and (max-width: 767px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 768px) and (max-width: 991px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 992px) and (max-width: 1199px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {


$n: 15; // 流星數量
$s: 300; // star數量


$shadow: ();

@for $i from 1 through $s {
  $x: random(1920);
  $y: random(1000);
  $shadow: $shadow, ( $x+px $y+px #fff) !global;
}

.star {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadow;
}

@for $i from 1 through $n {
  $v: random(90) + 9; // left
  $h: random(250) + 50; // top
  $d: random(70)/10 + 3; // 秒數
  .meteor-#{$i} {
    position: absolute;
    top: $h + px;
    left: $v*1%;
    width: 300px;
    height: 1px;
    transform: rotate(-45deg);
    background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
    animation: meteor $d+s linear  infinite;
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      height: 5px;
      border-radius: 50%;
      margin-top: -2px;
      background: rgba(#fff,.7);
      box-shadow: 0 0 15px 3px #fff;
    }
    
  }
}

@keyframes meteor {
  0% {
    opacity: 1;
    margin-top: -300px;
    margin-right: -300px;
  }
  12% {
    opacity: 0;
  }
  15% {
    margin-top: 300px;
    margin-left: -600px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

}

@media screen and (min-width: 1920px) and (max-width: 1920px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
    display: none;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}

@media screen and (min-width: 3840px) {


  $n: 15; // 流星數量
  $s: 300; // star數量
  
  
  $shadow: ();
  
  @for $i from 1 through $s {
    $x: random(1920);
    $y: random(1000);
    $shadow: $shadow, ( $x+px $y+px #fff) !global;
  }
  
  .star {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadow;
    display: none;
  }
  
  @for $i from 1 through $n {
    $v: random(90) + 9; // left
    $h: random(250) + 50; // top
    $d: random(70)/10 + 3; // 秒數
    .meteor-#{$i} {
      position: absolute;
      top: $h + px;
      left: $v*1%;
      width: 300px;
      height: 1px;
      transform: rotate(-45deg);
      background-image: linear-gradient(to right, #fff, rgba(255,255,255,0));
      animation: meteor $d+s linear  infinite;
      &:before {
        content: "";
        position: absolute;
        width: 4px;
        height: 5px;
        border-radius: 50%;
        margin-top: -2px;
        background: rgba(#fff,.7);
        box-shadow: 0 0 15px 3px #fff;
      }
      
    }
  }
  
  @keyframes meteor {
    0% {
      opacity: 1;
      margin-top: -300px;
      margin-right: -300px;
    }
    12% {
      opacity: 0;
    }
    15% {
      margin-top: 300px;
      margin-left: -600px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
}